import * as React from "react";

import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

import Navigation from "../components/Nav";

// markup
const IndexPage = () => {
  return (
    <>
      <div className="nk-wrap">
        <Helmet
          bodyAttributes={{
            class: "nk-body body-wider theme-dark mode-onepage",
          }}
        >
          <meta charset="utf-8" />
          <meta name="author" content="ChangeX" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="" />
          <link rel="shortcut icon" href="/assets/images/icon.png" />
          <title>Privacy Policy | ChangeX</title>

          <link rel="stylesheet" href="assets/css/vendor.bundle.css?ver=195" />
          <link rel="stylesheet" href="assets/css/style-dark.css" />
          <link rel="stylesheet" href="assets/css/theme.css?ver=195" />
        </Helmet>
        <header
          className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
          id="header"
        >
          <Navigation />
        </header>
        <main className="nk-pages">
          <section className="section bg-theme-alt ov-h pb-0 tc-light mt-50">
            <div className="container">
              <div className="section-head section-head-s4 wide-auto-sm text-center">
                <h5
                  className="title title-s2 title-s2-alt animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  Privacy Policy
                </h5>
              </div>

              <article className="text-content wrap mb-5">
                <h5 id="1-general-information">1. GENERAL INFORMATION</h5>

                <p>
                  This policy (“Policy”, together with our Terms of Use)
                  explains and sets out the basis for why and when we collect
                  personal information about the people who visit our
                  Website(s), how we use it, the conditions under which we may
                  disclose it to others and the measures we take to keep it
                  secure.
                </p>

                <p>
                  By visiting this Website you are accepting and consenting to
                  the practices described in this Policy. Please note that this
                  includes consenting to the collection and processing of any
                  personal information you provide, as described below. We may
                  amend this Policy from time to time so please check it
                  occasionally to ensure that you agree with any changes. Your
                  continued use of our Website will constitute your acceptance
                  of, and agreement to, any changes.
                </p>

                <h5 id="2-purpose">2. PURPOSE</h5>

                <p>The purpose of this Privacy Policy is to inform you of:</p>

                <p>
                  (i) the kinds of Personal Information we may collect about you
                  and how it may be used; (ii) use of information regarding IP
                  Addresses and our use of cookies; (iii) disclosure of Personal
                  Information to third parties; (iv) the transfer of your
                  Personal Information within and outside of the European
                  Economic Area (“EEA”); (v) your ability to correct, update and
                  delete your Personal Information; (vi) the security measures
                  we have in place to prevent the loss, misuse, or alteration of
                  Personal Information under our control; (vii) ChangeX’s
                  retention of your Personal Information.
                </p>

                <h5 id="3-responsible-person">3. RESPONSIBLE PERSON</h5>

                <p>
                  For any matters relating to data protection you may contact
                  compliance@changex.io in writing by e-mail.
                </p>

                <h5 id="4-data-processing">4. DATA PROCESSING</h5>

                <h6 id="4-1-information-we-automatically-collect">
                  4.1. INFORMATION WE AUTOMATICALLY COLLECT
                </h6>

                <p>
                  When you access or use our Website, we automatically collect
                  information about you. This information is used to provide
                  statistical data about our users browsing actions and
                  patterns, and does not personally identify individuals. This
                  information may include:
                </p>

                <p>
                  (i) Log Information: the type of browser you use, access
                  times, pages viewed, your IP address, and the page you visited
                  before navigating to our services. (ii) Device Information:
                  information about the computer or mobile device you use to
                  access our services, including the hardware model, operating
                  system and version, unique device identifiers, and mobile
                  network information.
                </p>

                <p>
                  The collection and processing of this technical data is for
                  the purpose of enabling the use of our Website, continuously
                  ensuring system security and stability, optimising our
                  Website, and for internal statistical purposes. This is our
                  legitimate interest in the processing of data in the sense of
                  Art. 6 Par. 1 lit. f GDPR.
                </p>

                <h6 id="4-2-ip-addresses">4.2. IP ADDRESSES</h6>

                <p>
                  We may collect information about your computer, including your
                  IP address, operating system and browser type, for system
                  administration and to report aggregate information to our
                  advertisers. This is statistical data about our users’
                  browsing actions and patterns and does not identify any
                  individual.
                </p>

                <p>
                  Furthermore, the IP addresses will be evaluated, together with
                  other data, in case of attacks on the network infrastructure
                  or other unauthorised use or misuse of the Website, for the
                  purpose of intelligence and protection, and if appropriate,
                  used in criminal proceedings for identification and civil and
                  criminal proceedings against the relevant users. This is our
                  legitimate interest in the processing of data in the sense of
                  Art. 6 Par. 1 lit. f GDPR.
                </p>

                <h6 id="4-3-usage-of-cookies">4.3. USAGE OF COOKIES</h6>

                <p>
                  The Website uses cookies. Cookies are text files that are
                  stored in a computer system via an Internet browser. More
                  detailed information on cookies and how they work can be found
                  at:{" "}
                  <a href="https://www.allaboutcookies.org">
                    https://www.allaboutcookies.org
                  </a>
                  .
                </p>

                <p>
                  We use information collected from cookies to assess the
                  effectiveness of our Website, analyse trends, and administer
                  the Platform. The information collected from cookies allows us
                  to determine which parts of our Website are most visited and
                  what difficulties our visitors may experience in accessing our
                  Website. With this knowledge, we can improve the quality of
                  your experience on the Platform by recognizing and delivering
                  more of the most desired features and information, as well as
                  by resolving access difficulties. We also use cookies, and/or
                  a technology known as web bugs or clear gifs, which are
                  typically stored in emails to help us confirm your receipt of,
                  and response to, our emails and to provide you with a more
                  personalized experience when using our Website.
                </p>

                <p>
                  By continuing to use our Website you agree to the following
                  cookies:
                </p>

                <p>
                  (i) Cookies that are strictly necessary to operate our
                  Website: cookies to log in, transact and otherwise use our
                  Website; (ii) Cookies for analytical and performance purposes;
                  (iii) Cookies for targeting user actions; (iv) Cookies from
                  third parties;
                </p>

                <p>
                  We use third party service provider(s), to assist us in better
                  understanding the use of our Website. Our service provider(s)
                  will place cookies on the hard drive of your computer and will
                  receive information that we select that will educate us on
                  such things as how visitors navigate around our Website, what
                  products are browsed, and general Transaction information. Our
                  service provider(s) analyses this information and provides us
                  with aggregate reports. The information and analysis provided
                  by our service provider(s) will be used to assist us in better
                  understanding our visitors’ interests in our Website and how
                  to better serve those interests. The information collected by
                  our service provider(s) may be linked to and combined with
                  information that we collect about you while you are using the
                  Platform. Our service provider(s) is/are contractually
                  restricted from using information they receive from our
                  Website other than to assist us. Third-party providers that we
                  use include:
                </p>

                <p>
                  (i) Google Analytics; (ii) Sendgrid; (iii) Google Adwords;
                  (iv) Facebook Analytics;
                </p>

                <h6 id="4-4-e-mail-subscription">4.4. E-MAIL SUBSCRIPTION</h6>

                <p>
                  Users who complete the registration process on our web site
                  are at the same time granting ChangeX permission to send them
                  e-mail messages for marketing and general communication
                  purposes at the e-mail address they have provided. This
                  consent constitutes the legal basis for our processing of your
                  e-mail address in the sense of Art. 6 Par. 1 lit. a GDPR. All
                  information gathered this way will never be passed on or sold
                  to any third party.
                </p>

                <p>
                  At the end of each newsletter a link is provided by means of
                  which you can unsubscribe at any time. After unsubscribing
                  your personal data will be deleted.
                </p>

                <h6 id="4-5-social-media">4.5. SOCIAL MEDIA</h6>

                <p>
                  We may use plug-ins from social networks such as Twitter,
                  GitHub, YouTube, Reddit, Facebook on our Website. When you
                  activate them (by clicking on them), the operators of the
                  respective social networks may record that you are on our
                  Website and may use this information. This processing of your
                  personal data lays in the responsibility of these individual
                  social media platforms and occurs according to their privacy
                  policy. Please check with these individual social media
                  platforms regarding their privacy policies. ChangeX is not
                  responsible for data collected by these individual social
                  media platforms. We only use these platforms to inform our
                  community of updates and answer user questions.
                </p>

                <h5 id="5-disclosure-of-information">
                  5. DISCLOSURE OF INFORMATION
                </h5>

                <p>
                  Your Personal Information may be disclosed to third parties
                  and/or legal authorities under the following
                  circumstances/conditions:
                </p>

                <h6 id="5-1-disclosure-to-third-parties">
                  5.1. DISCLOSURE TO THIRD PARTIES
                </h6>

                <p>
                  We may disclose your Personal Information with third parties
                  including:
                </p>

                <p>
                  (i) Business partners, suppliers, sub-contractors and other
                  service providers; (ii) Advertisers and/or advertising
                  networks that require data in order to select and show you
                  relevant advertisements; (iii) Analytics and/or search engine
                  providers that assist us in the optimization of our Website.
                </p>

                <p>
                  (iv) All our third party service providers are bound by
                  contract to protect and use our users’ Personal Information
                  only for the purposes listed above, except as otherwise
                  required by law.
                </p>

                <h6 id="5-2-disclosure-to-legal-authorities">
                  5.2. DISCLOSURE TO LEGAL AUTHORITIES
                </h6>

                <p>
                  We may share your Personal Information with law enforcement,
                  data protection authorities, government officials, and other
                  authorities in the following cases:
                </p>

                <p>
                  (i) If we believe disclosure is in accordance to any law,
                  regulation or legal procedure; (ii) If we think disclosure is
                  needed to prevent any harm or financial loss; (iii) If
                  disclosure is necessary to report certain illegal activity;
                  (iv) To protect the rights, property or safety of ChangeX and
                  its community. (v) If we believe your actions are in violation
                  of this Privacy Policy and/or our Terms of Use.
                </p>

                <h5 id="6-international-transfer">6. INTERNATIONAL TRANSFER</h5>

                <p>
                  We store and process your Personal Information in data centers
                  around the world, wherever ChangeX facilities or service
                  providers are located. As such, we may transfer your Personal
                  Information outside of the EEA. Such transfers are undertaken
                  in accordance with our legal and regulatory obligations.
                </p>

                <h5 id="7-minors">7. MINORS</h5>

                <p>
                  THE SERVICE IS NOT FOR PERSONS UNDER THE AGE OF 18 OR FOR ANY
                  USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE SERVICE. IF YOU
                  ARE UNDER 18 YEARS OF AGE, THEN YOU MUST NOT USE OR ACCESS THE
                  SERVICE AT ANY TIME OR IN ANY MANNER. By accessing or using
                  the Service, you affirm that you are at least 18 years of age.
                </p>

                <p>
                  ChangeX does not knowingly collect or use any personal data
                  from minors. A minor may be able to willingly share personal
                  information with others, depending on the products and/or
                  media channels used. If a minor provides us with their
                  information without the consent of their parent or guardian,
                  we will ask the parent or guardian to contact us for the
                  purpose of deleting that information.
                </p>

                <h5 id="8-links">8. LINKS</h5>

                <p>
                  ChangeX may provide references and/or links to other Websites.
                  This Policy applies only to ChangeX’s Website.
                </p>

                <h5 id="9-correction-updating-deleting-of-personal-information">
                  9. CORRECTION/UPDATING/DELETING OF PERSONAL INFORMATION
                </h5>

                <p>
                  You may access your Personal Information whenever you want by
                  sending us an e-mail to compliance@changex.io. Please, have in
                  mind that there might be a small fee in order to cover our
                  costs for providing you the desired access.
                </p>

                <p>
                  You have the right to correct, update or delete any inaccurate
                  and/or incorrect data by contacting us at
                  compliance@changex.io.
                </p>

                <p>
                  The deletion or destruction requests of both your Account and
                  Personal Information will be reviewed and processed only where
                  this is not inconsistent with its legal and regulatory
                  obligations.
                </p>

                <h5 id="10-security-of-personal-information">
                  10. SECURITY OF PERSONAL INFORMATION
                </h5>

                <p>
                  We use appropriate technical and organizational security
                  measures to protect your stored personal data against
                  manipulation, partial or complete loss, and unauthorized
                  access by third parties. Our security measures are
                  continuously being improved in line with technical
                  developments. Part of these measures are:
                </p>

                <p>
                  (i) Password protected directories and databases; (ii) Only
                  authorised personnel with access to your Personal Information,
                  who are required to treat the information as confidential;
                  (iii) Secure Sockets Layered (SSL) technology to encrypt and
                  send your information across the Internet securely; (iv) PCI
                  Scanning to prevent your information from being hacked.
                </p>

                <h5 id="11-retention-of-personal-information">
                  11. RETENTION OF PERSONAL INFORMATION
                </h5>

                <p>
                  We retain Personal Information for as long as necessary to
                  fulfil purposes described in this Privacy Policy, subject to
                  our own legal and regulatory obligations.
                </p>

                <h5 id="12-changes-and-updates-to-this-privacy-policy">
                  12. CHANGES AND UPDATES TO THIS PRIVACY POLICY
                </h5>

                <p>
                  This Privacy Policy may be revised, modified, updated and/or
                  supplemented at any time, without prior notice. When we make
                  changes to this Privacy Policy, we will notify all users on
                  our Website, and make the amended Privacy Policy available on
                  our Website.
                </p>
              </article>
            </div>
          </section>
          <Footer />
        </main>
      </div>
      <div className="preloader">
        <span className="spinner spinner-round"></span>
      </div>
    </>
  );
};

export default IndexPage;
